import React, { useState } from 'react'

import {EuiButton} from "@elastic/eui"

import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel"

import WinPhone01 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/WP_App1.webp'
import WinPhone02 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/WP_App2.webp'
import WinPhone03 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/WP_App3.webp'
import WinPhone04 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/WP_App4.webp'


const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen }) => {
  return (
    <div>
      <AutoRotatingCarousel
        label="Return"
        open={handleOpen.open}
        onClose={() => setHandleOpen({ open: false })}
        onStart={() => setHandleOpen({ open: false })}
        mobile
        autoplay={true}
        style={{ position: "absolute" }}
      >
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone01} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Multiview"
          subtitle="Here, only single images are taken by the cameras at a fixed interval. You can adjust this interval in the general settings."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone02} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Multiview"
          subtitle="Click on a single camera to access the video livestream."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone03} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Multiview"
          subtitle="Double-tapping on the video displays all functions. A description of all buttons can be found below and in the Help menu."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone04} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Multiview"
          subtitle="Press the gear button to display the video settings."
        />
      </AutoRotatingCarousel>
    </div>
  );
}

function MultiviewGallery() {
  const [handleOpen, setHandleOpen] = useState({ open: false });
  const handleClick = () => {
    setHandleOpen({ open: true });
  };
  return (
    <>
      <EuiButton fill onClick={handleClick}>
        Image Gallery
      </EuiButton>
      <AutoRotatingCarouselModal
        handleOpen={handleOpen}
        setHandleOpen={setHandleOpen}
      />
    </>
  );
}

export default MultiviewGallery