import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'DDNS',
      href: '/en/Software/Windows/InstarVision/Windows_Phone/DDNS/',
    },
    {
      text: 'Multiview',
      href: '/en/Software/Windows/InstarVision/Windows_Phone/Multiview/'
    },
    {
      text: 'P2P',
      href: '/en/Software/Windows/InstarVision/Windows_Phone/P2P/'
    },
    {
      text: 'Recording',
      href: '/en/Software/Windows/InstarVision/Windows_Phone/Recording/'
    },
    {
      text: 'Settings',
      href: '/en/Software/Windows/InstarVision/Windows_Phone/Settings/'
    },
    {
      text: '◄ InstarVision WP',
      href: '/en/Software/Windows/InstarVision/Windows_Phone/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Software Platform for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}