import React from 'react'

import {
  EuiSpacer,
  EuiTitle,
  EuiText
} from '@elastic/eui'

import FloatingGallery from "../../../../../../components/Software/Windows/InstarVision/Windows_Phone/instarvision-wp-multiview-floating-gallery"

import MultimediaCards from "../../../../../../components/Software/Windows/InstarVision/Windows_Phone/instarVisionMultimediaCards"

import NavButtons from '../../../../../../components/Software/Windows/InstarVision/Windows_Phone/NavButtons'

import SEOHelmet from '../../../../../../components/Layout/SEOHelmet'
import BreadCrumbs from '../../../../../../components/Layout/BreadCrumbs'


const seodata = {
  title: 'Multiview Menu :: InstarVision for Windows Phone',
  description: 'Keep an eye on all your cameras in the Multiview Menu. Click on any camera to access their video live stream and control them via function buttons.',
  image: '/images/Search/P_SearchThumb_InstarVision_WP.png',
  twitter: '/images/Search/P_SearchThumb_InstarVision_WP.webp',
  locationEN: '/en/Software/Windows/InstarVision/Windows_Phone/Multiview/',
  locationDE: '/de/Software/Windows/InstarVision/Windows_Phone/Multiview/',
  locationFR: '/fr/Software/Windows/InstarVision/Windows_Phone/Multiview/'
}

function InstarVisionMultimedia(props) {
  return (
    <React.Fragment>

      <SEOHelmet
        title={seodata.title}
        description={seodata.description}
        image={seodata.image}
        twitter={seodata.twitter}
        location={props.location} />

      <BreadCrumbs
          locationBC={props.location}
          locationEN={seodata.locationEN}
          locationDE={seodata.locationDE}
          locationFR={seodata.locationFR}
          crumbLabel="Multimedia" />
          
      <NavButtons />

      <EuiTitle size="l">
        <h1>InstarVision Surveillance Center</h1>
      </EuiTitle>
      <EuiTitle size="m">
        <h2>Windows Phone Software</h2>
      </EuiTitle>
      <EuiTitle size="s">
        <h4>The Multiview Menu allows you to keep an eye on all your cameras</h4>
      </EuiTitle>
      <EuiSpacer size="l" />
      <EuiText>
        <p>Every camera that you add to the app will be displayed in the Multiview Menu. The Video will be displayed with a low refresh rate to keep bandwidth requirements low. Simply touch the video to access the corresponding cameras live stream.</p>
      </EuiText>
      <EuiSpacer size="xl" />
      <FloatingGallery />
      <EuiSpacer size="xl" />
      <MultimediaCards />
    </React.Fragment>
  );
}

export default InstarVisionMultimedia